<template>
  <section class="carousel-section">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <!-- CMS FETCH -->

        <!-- <div
          v-for="(banner, index) in carouselImages"
          :key="index"
          :class="['carousel-item', { active: index === 0 }]"
        >
          <img
            class="d-block w-100"
            style="height: 90px"
            :src="getBannerUrl(banner.url)"
          />
        </div> -->

        <!-- CMS FETCH -->

        <!-- <div class="carousel-item">
        <img class="d-block w-100" src="/img/carousel/comet-crash.png">

        <img
            class="d-block w-100"
            style="height: 90px"
            src="/img/carousel/falcon-bet1.jpg"
          />
        </div>
        <div class="carousel-item">

        <img class="d-block w-100" src="/img/carousel/crash-mamilli.png">

        <img
            class="d-block w-100"
            style="height: 90px"
            src="/img/carousel/falcon-bet3.jpg"
          />
        </div>

        <div class="carousel-item ">
          <img class="d-block w-100" src="/img/carousel/f1.png">
        </div>
        <div class="carousel-item ">
        <img class="d-block w-100" src="/img/carousel/carousel_4.png">
        <img class="d-block w-100" src="/img/carousel/freespin.png">
        </div>
        <div class="carousel-item ">
          <img class="d-block w-100" src="/img/carousel/JACKPOT.png">
        </div> -->

        <div class="carousel-item active">
          <!-- <img class="d-block w-100" src="/img/carousel/carousel_3.png"> -->
          <img class="d-block w-100" src="/img/carousel/cheza_leo.png" />
        </div>
        <div class="carousel-item">
          <!-- <img class="d-block w-100" src="/img/carousel/comet-crash.png"> -->
          <img class="d-block w-100" src="/img/carousel/freespin1.png" />
        </div>
        <div class="carousel-item">
          <!-- <img class="d-block w-100" src="/img/carousel/crash-mamilli.png"> -->
          <img class="d-block w-100" src="/img/carousel/djp1.png" />
        </div>
        <!-- <div class="carousel-item ">
          <img class="d-block w-100" src="/img/carousel/f1.png">
        </div> -->
        <div class="carousel-item">
          <!-- <img class="d-block w-100" src="/img/carousel/carousel_4.png"> -->
          <img class="d-block w-100" src="/img/carousel/karibu1.png" />
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>
<script>
import axios from "@/services/cms";

export default {
  name: "Slider",
  data() {
    return {
      carouselImages: [],
    };
  },
  mounted() {
    this.getCarousels();
  },
  methods: {
    getBannerUrl(url) {
      if (url && !url.startsWith("https://")) {
        return `https://${url}`;
      }
      return url;
    },
    async getCarousels() {
      try {
        var response = await axios.get(
          "/get/banners",
          {},
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        if (response.status === 200) {
          // Adjust based on the structure of response.data
          this.carouselImages = response.data.banners;
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching carousel:",
          error.response?.data || error.message
        );
      }
    },
  },
};
</script>
